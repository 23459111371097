import { User, UserData } from './types';

export class HubspotClient {
    private static async makeRequest(data: any) {
        const response = await fetch('/api/hubspot/contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: data.email,
                first_name: data.firstName,
                last_name: data.lastName,
                id: '1',
                login_count: '1',
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to update HubSpot contact');
        }

        return response.json();
    }

    public static async createOrUpdateContact(userData: UserData): Promise<boolean> {
        try {
            await this.makeRequest(userData);
            return true;
        } catch (error) {
            console.error('Error creating/updating HubSpot contact:', error);
            return false;
        }
    }

    public static async handleUserLogin(user: User): Promise<void> {
        await this.createOrUpdateContact({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            id: user.id,
            loginCount: user.loginCount,
        });
    }
}
