import { v4 as uuidv4 } from 'uuid';
import { base64StringToBlob } from 'blob-util';
import { AVATAR_STORAGE_KEY, NOT_AVAILABLE } from '@config/constants';
import Mixpanel from '../analytics/Mixpanel';
import NameGenerator from './NameGenerator';
import localforage from 'localforage';
import { AnalyticsEvent, AnalyticsEventName } from '@analytics/AnalyticsEvent';
import Logger from '../logging/logger';
import URLParams from '../core/URLHandler';
import { Microsoft, MicrosoftUserData } from './microsoft';
import Google from './google';
import Experiment from '../experiments/experiment';
import { HubspotClient } from './hubspot';

export interface UserDetails {
    id: string;
    name: string;
    photoBase64: string;
    pictureLoaded: boolean;
    $email: string;
    provider: SSOProvider | undefined;
    variant: string;
    loginCount?: number;
}

export enum SSOProvider {
    GOOGLE = 'google',
    GOOGLE_ONE_TAP = 'google_one_tap',
    LINKEDIN = 'linkedin',
    FACEBOOK = 'facebook',
    MICROSOFT = 'microsoft',
}

const USER_EMPTY: UserDetails = {
    id: '',
    name: '',
    photoBase64: '',
    pictureLoaded: false,
    $email: '',
    provider: undefined,
    variant: '',
};

class Auth {
    public static googleClientId: string;
    public static linkedInClientId: string;
    public static facebookAppId: string;
    public static microsoftClientId: string;
    public static microsoftTenantId: string;

    public static loggedIn: boolean = false;
    private static fedcmEnabled: boolean = false;
    private static user: UserDetails = USER_EMPTY;
    private static currentBlobUrl: string | null = null;
    private static userToken: string = '';

    public static init(
        googleClientId: string,
        linkedInClientId: string,
        facebookAppId: string,
        microsoftClientId: string,
        microsoftTenantId: string
    ) {
        Auth.googleClientId = googleClientId;
        Auth.linkedInClientId = linkedInClientId;
        Auth.facebookAppId = facebookAppId;
        Auth.microsoftClientId = microsoftClientId;
        Auth.microsoftTenantId = microsoftTenantId;
        Auth.loadUserToken();

        /* Initialize microsoft SSO. */
        void Microsoft.init(microsoftClientId);

        /* Cleanup blob url on exit. */
        window.addEventListener('unload', () => {
            Auth.cleanupBlobUrl();
        });
    }

    private static generateUserId(): string {
        return uuidv4();
    }

    public static async loadUserFromLocalStorage(): Promise<void> {
        const userString = localStorage.getItem('user-string');

        Auth.user = {
            id: Auth.generateUserId(),
            name: NameGenerator.generate(),
            photoBase64: '',
            pictureLoaded: false,
            $email: '',
            provider: undefined,
            variant: '',
        };

        if (userString) {
            const tempUser = JSON.parse(userString) as UserDetails;

            (Object.keys(Auth.user) as Array<keyof UserDetails>).forEach((key) => {
                if (key in tempUser) {
                    (Auth.user[key] as any) = tempUser[key]!;
                }
            });
        }

        localStorage.setItem('user-string', JSON.stringify(Auth.user));

        const userLoggedIn = localStorage.getItem('user-logged-in');
        if (userLoggedIn == null) {
            Auth.setLoggedIn(false);
        } else {
            Auth.setLoggedIn(userLoggedIn === 'true');
        }
    }

    private static delay = (ms: number): Promise<void> => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    public static async getAvatarPictureURL(): Promise<string> {
        if (!Auth.user.photoBase64 || !Auth.loggedIn) {
            return '';
        }

        return URL.createObjectURL(base64StringToBlob(Auth.user.photoBase64));
    }

    public static sessionId(forceNew: boolean = false): string {
        let sessionId = sessionStorage.getItem('sessionId');
        if (!sessionId || forceNew) {
            sessionId = uuidv4(); // Generate a new UUID
            sessionStorage.setItem('sessionId', sessionId);
        }
        return sessionId;
    }

    public static loadUserToken() {
        const getUrlToken = () => {
            return (
                URLParams.userToken ||
                process.env.REACT_APP_USER_TAG ||
                localStorage.getItem('token') ||
                NOT_AVAILABLE
            );
        };

        const urlToken = getUrlToken();
        localStorage.setItem('token', urlToken);
        Auth.userToken = urlToken;
    }

    public static getUserToken(): string {
        return Auth.userToken;
    }

    public static async logIn(provider: SSOProvider, response: any) {
        // Send event first so the last event of the annon user in mixpanel will be the login event
        AnalyticsEvent.create(AnalyticsEventName.SIGN_UP_SUCCESS)
            .set('type', provider.toLowerCase())
            .mixpanelTrack()
            .googleAnalyticsTrack();

        switch (provider) {
            case SSOProvider.GOOGLE:
            case SSOProvider.GOOGLE_ONE_TAP:
                await Google.logIn(response);
                break;

            case SSOProvider.MICROSOFT:
                await Microsoft.parseLoginResponse(response as MicrosoftUserData);
                break;
            default:
                Logger.error('Unknown provider: ', provider);
        }
    }

    public static commonLogIn(
        name: string,
        photoBase64: string,
        pictureLoaded: boolean,
        email: string,
        provider: SSOProvider
    ) {
        let id: string;
        let variant: string;

        if (Auth.getUser().id) {
            id = Auth.getUser().id;
            variant = Auth.getUser().variant;
        } else {
            variant = Experiment.variant;
            id = '';
        }

        const user: UserDetails = {
            id: id,
            name: name,
            photoBase64,
            pictureLoaded: pictureLoaded,
            $email: email,
            provider: provider,
            variant: variant,
        };

        Mixpanel.handleLogIn(user.$email, user);

        // Create/update HubSpot contact for the user
        void HubspotClient.handleUserLogin({
            email: user.$email,
            firstName: user.name.split(' ')[0],
            lastName: user.name.split(' ').slice(1).join(' '),
            loginCount: Number(user.loginCount) || 1,
            id: Number(user.id) || 0,
        });

        Auth.user = user;
        localStorage.setItem('user-string', JSON.stringify(user));
        Auth.setLoggedIn(true);
    }

    private static setLoggedIn(loggedIn: boolean) {
        Auth.loggedIn = loggedIn;
        localStorage.setItem('user-logged-in', loggedIn.toString());
    }

    public static handleSSOFailure(provider: SSOProvider, error: any) {
        AnalyticsEvent.create(AnalyticsEventName.SSO_FAILED)
            .set('sso', provider.toLowerCase())
            .mixpanelTrack();
        Logger.error('Error in SSO login: ', provider, error);
    }

    public static signOut() {
        Auth.setLoggedIn(false);
        Auth.cleanupBlobUrl();
        void localforage.removeItem(AVATAR_STORAGE_KEY);
        Auth.user = USER_EMPTY;
    }

    public static isFedcmEnabled(): boolean {
        let fedcmEnabled: boolean = false;

        /* Check if the browser supports FedCM. */
        const supported = 'IdentityCredential' in window;
        if (!supported) {
            Auth.fedcmEnabled = false;
        } else {
            // Try to determine if FedCM is enabled
            (window as any).navigator?.credentials
                ?.get?.({ identity: { providers: [] } })
                .then((_: any) => {
                    fedcmEnabled = true;
                })
                .catch((error: any) => {
                    Auth.fedcmEnabled = !error.message?.includes('disabled');
                });
        }
        Auth.fedcmEnabled = fedcmEnabled;
        return Auth.fedcmEnabled;
    }

    public static getUser(): UserDetails {
        return Auth.user;
    }

    public static setUserId(id: string) {
        Auth.user.id = id;
    }

    public static async cacheAndConvertImage(key: string, blob: Blob): Promise<string | null> {
        try {
            Auth.cleanupBlobUrl();

            // Cache the blob
            await localforage.setItem(key, blob);

            // Convert to base64
            return await new Promise<string>((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = (reader.result as string)
                        .replace('data:', '')
                        .replace(/^.+,/, '');
                    resolve(base64String);
                };
                reader.readAsDataURL(blob);
            });
        } catch (e) {
            Logger.error('Error caching image', e);
            return null;
        }
    }

    private static cleanupBlobUrl() {
        if (Auth.currentBlobUrl?.startsWith('blob:')) {
            URL.revokeObjectURL(Auth.currentBlobUrl);
            Auth.currentBlobUrl = null;
        }
    }
    private static updateUserProperty<K extends keyof UserDetails>(property: K, value: any) {
        Auth.user[property] = value;
    }

    public static setVariant(variant: string) {
        Auth.updateUserProperty('variant', variant);
        Auth.user.variant = variant;
        localStorage.setItem('user-string', JSON.stringify(Auth.user));
    }
}

export default Auth;
